.admin-main-loader {
    background: #101010;
    height: 100vh;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
}

.admin-main-loader img {
    width: 50px;
}
.form-loader {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #000000bd;
    z-index: 9999;
    display: none;
}
.form-loader svg {
    width: 90px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.form-loader.show {
    display: block;
}
.react-pagination {
    display: flex;
    align-items: center;
}
.react-pagination button {
    padding: 5px 10px;
    margin: 0 4px;
    border: 1px solid #bdbdbd;
    color: #008c44;
    outline: none;
}
.react-pagination button:hover {
    background: #008c44;
    color: #fff;
    border-color: #008c44;
}
.btn {
    min-width: 40px;
}
.btn:active, .btn:focus, .btn:hover {
    outline: none;
    box-shadow: none;
    box-shadow: none;
    outline: none;
}
.btn-primary {
    background: #03396c;
    border-color: #03396c;
}

.btn-primary:hover {
    background: #ed716c;
    border-color: #ed716c;
    /* color: #000; */
}

.input-group-text {
    color: #03396c;
}
.logo-brand {
    max-height: 70px;
}
.badge {
    font-size: 11px;
    font-weight: 600;
    padding: 5px 9px;
}
.field-loader {
    background: #ededed;
    height: 40px;
    width: 100%;
    border-radius: 5px;
    animation: loader 1s infinite linear;
}
@keyframes loader{
    0%{background: #ededed}
    50%{background: #d7d7d7}
    100%{background: #ededed}
}
#menu li ul a.active {
    background: #3e3e3e;
}
#menu li ul a.active:hover {
    color: #fff;
}
.table tbody > tr > td img {
    width: 80px;
}
.cke_browser_webkit.cke_reset {
    width: 100%;
}
textarea.form-control {
    height: 150px;
    resize: none;
}

.custom-file-upload-wrapper{
    width: 100%;
    display: flex;
    align-items: center;
}

.custom-file-upload-wrapper span{
    cursor: pointer;
    color: #212529;
    background-color: #e9ecef;
    border: 1px solid #ced4da;
    border-right: none;
    padding: 10px 20px;
    border-radius: 4px 0 0 4px;
}

.custom-file-upload{
    /* flex: 1 1 0%; */
    padding: 10px 20px;
    cursor: pointer;
    color: #212529;
    background-color: #e9ecef;
    border: 1px solid #ced4da;
    border-radius: 0 4px 4px 0;
}
.custom-file-preview {
    padding-left: 10%;
    /* flex: 1 1 0%;  */
}


.pagination-seller-journey {
    display: inline-block;
  }

     /* Custom styles for chip container */
.chip-container {
    padding-left: 3px;
    display: flex;
    flex-wrap: wrap;
    /* gap: 5px; /*space between chips */
    /* margin-bottom: 10px; space below the chip container */
    align-items: center;
  }
  
  /* Styles for individual chips */
  .chip {
    align-items: center;
    background-color: rgb(229 231 235 ); /* Light gray background */
    border-radius: 5px; /* Rounded corners */
    padding: 3px 8px 3px 8px; /* Space inside the chip */
    color: #333; /* Text color */
    /* box-shadow: 0 2px 4px rgba(0,0,0,0.1); */
    height: 25px;
    font-size: 12px;
    margin-left: 2px;
   }
  
  .chip i {
    font-size: 10px;
    cursor: pointer; /* Pointer cursor on hover */
    color: #d9534f; /* Red color for the close icon */
  }


.no-hover-effect:focus {
    color: #212529;
    background-color: #fff;
    border-style: none !important;
    outline: 0;
    box-shadow: none !important;
}

